/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.MinecraftLandingPage .mainFeatures {
  background-color: #534468;
}
.MinecraftLandingPage .mainFeatures .FeaturesList .header .title {
  color: white;
}
.MinecraftLandingPage .HowItWorks {
  background-color: #f9f6ef;
}
.MinecraftLandingPage .ProductOverview {
  background: #f9f6ef;
}
.MinecraftLandingPage .GenericLandingAboveFold {
  background: #3873f7;
}
.MinecraftLandingPage .GenericLandingAboveFold .left > .title > .minecraft {
  color: #f9cc26;
}
@media (max-width: 767px) {
}
.MinecraftLandingPage .aboveFold {
  padding: 15vh 8rem 8rem;
  overflow: hidden;
  position: relative;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .aboveFold {
    padding: 2rem 2rem 12rem;
    text-align: center;
    height: auto;
  }
}
.MinecraftLandingPage .aboveFold .title {
  color: #745a9e;
  font-weight: 600;
  font-size: 4rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .aboveFold .title {
    font-size: 3rem;
  }
}
.MinecraftLandingPage .aboveFold .subtitle {
  color: #745a9e;
  font-weight: 600;
  font-size: 2rem;
  margin-top: 1.5rem;
  max-width: 40rem;
}
.MinecraftLandingPage .aboveFold .landingImage {
  margin-top: 1.5rem;
}
.MinecraftLandingPage .aboveFold .watchVideoDesktop {
  margin-top: 2.5rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .aboveFold .watchVideoDesktop {
    display: none;
  }
}
.MinecraftLandingPage .aboveFold .watchVideoMobile {
  display: none;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .aboveFold .watchVideoMobile {
    display: inline-block;
    margin-top: -8rem;
  }
}
.MinecraftLandingPage .aboveFold .animationFrame {
  position: absolute;
  right: 0;
  bottom: -2rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .aboveFold .animationFrame .desktop {
    display: none;
  }
}
.MinecraftLandingPage .aboveFold .animationFrame .mobile {
  display: none;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .aboveFold .animationFrame .mobile {
    display: block;
  }
}
.MinecraftLandingPage .coreFeatures {
  width: 100%;
  padding: 0 16rem;
  background: #4a82ff;
  overflow: hidden;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures {
    display: none;
  }
}
@media (max-width: 1199px) {
  .MinecraftLandingPage .coreFeatures {
    padding: 20px 40px;
  }
}
.MinecraftLandingPage .coreFeatures h2 {
  font-weight: 600;
  color: #fff;
  font-size: 5.2rem;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures h2 {
    text-align: center;
  }
}
.MinecraftLandingPage .coreFeatures .description {
  color: #fff;
  font-size: 2.2rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .description {
    text-align: center;
  }
}
.MinecraftLandingPage .coreFeatures .createWorlds {
  margin-top: -45%;
  margin-left: -46rem;
  width: 142rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .createWorlds {
    margin-left: -15rem;
    margin-top: -35%;
    width: 62rem;
  }
}
.MinecraftLandingPage .coreFeatures .battleFriends {
  margin-top: -30%;
  transform: scale(1.5);
}
.MinecraftLandingPage .coreFeatures .codingProTop {
  position: relative;
  top: 0;
  left: -35rem;
  width: 146rem;
  height: 21rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .codingProTop {
    display: none;
  }
}
.MinecraftLandingPage .coreFeatures .codingProBottom {
  width: 137rem;
  position: relative;
  top: -34rem;
  left: -28rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .codingProBottom {
    left: -36rem;
  }
}
.MinecraftLandingPage .coreFeatures .createWorldsLeft {
  width: 40%;
  float: left;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .createWorldsLeft {
    width: 100%;
    float: none;
  }
}
.MinecraftLandingPage .coreFeatures .createWorldsRight {
  width: 60%;
  float: right;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .createWorldsRight {
    width: 100%;
    float: none;
    margin-left: -12rem;
    margin-top: 0;
  }
}
.MinecraftLandingPage .coreFeatures .codingProLeft {
  width: 60%;
  float: left;
  margin-top: -20rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .codingProLeft {
    width: 100%;
    float: none;
    margin-top: 6rem;
    margin-left: -5rem;
  }
}
.MinecraftLandingPage .coreFeatures .codingProRight {
  width: 40%;
  float: right;
  margin-top: -20rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .codingProRight {
    width: 100%;
    float: none;
    margin-top: -40rem;
  }
}
.MinecraftLandingPage .coreFeatures .battleFriendsLeft {
  width: 40%;
  float: left;
  margin-top: -36rem;
  padding-bottom: 8rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .battleFriendsLeft {
    width: 100%;
    float: none;
    margin-top: 18rem;
  }
}
.MinecraftLandingPage .coreFeatures .battleFriendsRight {
  width: 60%;
  float: right;
  margin-top: -36rem;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .coreFeatures .battleFriendsRight {
    width: 100%;
    float: none;
    margin-top: 11rem;
    margin-left: -4rem;
  }
}
.MinecraftLandingPage .whatCustomersSay {
  background: #f9f6ef;
}
.MinecraftLandingPage .whatCustomersSay > .title {
  text-align: center;
  font-size: 3.2rem;
  font-weight: bold;
  color: #745a9e;
  padding-top: 4rem;
}
.MinecraftLandingPage .whatCustomersSay .animationFrame {
  display: block;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .whatCustomersSay .animationFrame .desktop {
    display: none;
  }
}
.MinecraftLandingPage .whatCustomersSay .animationFrame .mobile {
  display: none;
}
@media (max-width: 767px) {
  .MinecraftLandingPage .whatCustomersSay .animationFrame .mobile {
    display: block;
  }
}
.MinecraftLandingPage .PaymentPlansWrapper {
  background: #f9f6ef;
}
.MinecraftLandingPage .PaymentPlans {
  background: #f2eee5;
}
.MinecraftLandingPage .PaymentPlans .bottomInfo {
  background: #f9f6ef;
}
